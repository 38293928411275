import React, {memo, useCallback, useMemo, useState} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';

import {COLOR} from 'bridge';
import {getColorTitle} from '../utils/enums';
import Radio from './atomic/Radio';
import Div from './atomic/Div';
import {Theme} from '../theme/types';

interface Props {
  controlled?: boolean;
  color: COLOR;
  title: string;
  onChange(color: COLOR): any;
  onSave?(): any;
}

const useStyles = makeStyles<Theme>(theme => ({
  root: {},
  title: {
    color: 'black',
    fontSize: 15,
    lineHeight: '22px',
    letterSpacing: -0.4,
    marginBottom: 15,
    display: 'flex',
    alignItems: 'center',
  },
  titleSeparator: {
    borderTop: '0.5px solid rgba(0, 0, 0, 0.12)',
    flex: '1 0 auto',
    margin: '0 6px',
  },
  action: {
    color: '#818C99',
    fontSize: 10,
    lineHeight: '18px',
    fontWeight: theme.typography.fontWeightMedium,
  },
  actionEditing: {
    color: theme.palette.primary.main,
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  gridItem: {
    flex: '1 0 auto',
  },
  radio: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
  },
}));

/**
 * Предоставляет возможность выбирать цвет
 * @type {React.NamedExoticComponent<Props>}
 */
const ColorSelector = memo((props: Props) => {
  const {onSave, onChange, color, title, controlled} = props;
  const [editing, setEditing] = useState(false);
  const mc = useStyles(props);
  const colorsSorted = useMemo<COLOR[]>(() => {
    return Object
      .values(COLOR)
      .sort((a, b) => getColorTitle(a).length - getColorTitle(b).length)
  }, []);
  const radioDisabled = controlled && !editing;

  const onActionClick = useCallback(() => {
    if (editing) {
      if (onSave) {
        onSave();
      }
      setEditing(false);
    } else {
      setEditing(true);
    }
  }, [editing, onSave]);

  return (
    <Div className={mc.root}>
      <div className={mc.title}>
        {title}
        {controlled &&
        <>
          <div className={mc.titleSeparator}/>
          <div
            className={c(mc.action, {[mc.actionEditing]: editing})}
            onClick={onActionClick}
          >
            {editing ? 'Сохранить изменения' : 'Редактировать'}
          </div>
        </>}
      </div>
      <div className={mc.grid}>
        {colorsSorted.map(c => (
          <div className={mc.gridItem} key={c}>
            <Radio
              checked={color === c}
              onChange={() => onChange(c)}
              disabled={radioDisabled}
              className={mc.radio}
            >
              {getColorTitle(c)}
            </Radio>
          </div>
        ))}
      </div>
    </Div>
  );
});

export default ColorSelector;
