import {COLOR} from 'bridge';

type CartColors = Record<COLOR, string>;
type CostumeColors = Record<COLOR, {
  body: string;
  helmet: string;
}>;

const cartColors: CartColors = {
  [COLOR.BLUE]: '#75B0FF',
  [COLOR.YELLOW]: '#FFD60A',
  [COLOR.RED]: '#F04C43',
  [COLOR.PURPLE]: '#BDA6FF',
  [COLOR.GREEN]: '#00BDB6',
};

const costumeColors: CostumeColors = {
  [COLOR.BLUE]: {
    body: '#2D64AE',
    helmet: '#3A88F0',
  },
  [COLOR.YELLOW]: {
    body: '#AC9B02',
    helmet: '#FFD60A',
  },
  [COLOR.RED]: {
    body: '#DA473F',
    helmet: '#FF766F',
  },
  [COLOR.PURPLE]: {
    body: '#6D69AB',
    helmet: '#9690EA',
  },
  [COLOR.GREEN]: {
    body: '#2A7771',
    helmet: '#3CA19D',
  },
};

/**
 * Возвращает hex-цвет карта
 * @param {COLOR} color
 * @returns {string}
 */
export function getCartColor(color: COLOR) {
  return cartColors[color];
}

/**
 * Возвращает настройки цветов для костюма
 * @param {COLOR} color
 * @returns {string}
 */
export function getCostumeColors(color: COLOR) {
  return costumeColors[color];
}
