import React, {memo, useCallback, useEffect, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Div from '../../atomic/Div';
import Button from '@vkontakte/vkui/dist/components/Button/Button';

interface Props {
  index: number;
  title: string;
  options: string[];
  selectedOptions: number[];
  onOptionChoose(index: number): void;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    paddingBottom: 60,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: theme.typography.fontWeightMedium,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    fontSize: 17,
    lineHeight: '22px',
    letterSpacing: -0.408,
    minHeight: 140,
    textAlign: 'center',
    marginBottom: 14,
  },
  option: {
    '&[disabled], &.Tappable--active': {
      opacity: 1,
    },

    '& + &': {
      marginTop: 10,
    },
  },
}));

const GameQuestion = memo((props: Props) => {
  const {title, options, onOptionChoose, selectedOptions, index} = props;
  const mc = useStyles(props);
  const [blockedOpts, setBlockedOpts] = useState(selectedOptions);

  const onOptionClick = useCallback((index: number) => {
    onOptionChoose(index);
    setBlockedOpts(blocked => [...blocked, index]);
  }, [onOptionChoose]);

  useEffect(() => setBlockedOpts([]), [index]);

  return (
    <Div className={mc.root}>
      <Div className={mc.title}>{title}</Div>
      {options.map((o, idx) => {
        const selected = blockedOpts.includes(idx);
        return (
          <Button
            className={mc.option}
            mode={selected ? 'secondary' : 'primary'}
            size={'xl'}
            onClick={() => onOptionClick(idx)}
            disabled={selected}
            key={idx}
          >
            {o}
          </Button>
        )
      })}
    </Div>
  );
});

export default GameQuestion;
