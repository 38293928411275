import React, {memo, ReactNode, useCallback, useEffect, useState} from 'react';

import ViewLoadingSpinner from '../../ViewLoadingSpinner';
import ViewLoadingError from '../../ViewLoadingError';
import View from '../../View';
import GameRaceTimer from './GameRaceTimer';
import GameQuestion from './GameQuestion';
import GameRaceRoad from './GameRaceRoad';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader
  from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';

import {
  useLazyQuery,
  useMutation,
} from '@apollo/react-hooks';
import {
  answerQuestionMutation,
  AnswerQuestionMutation,
  userRaceQuery,
  UserRaceQuery,
  UserRace,
} from 'bridge';

import useSelector from '../../../hooks/useSelector';
import useUserRaceSubscription from '../../../hooks/useUserRaceSubscription';
import useActions from '../../../hooks/useActions';
import {layoutActions} from '../../../redux/reducers/layout';

interface Props {
  onRaceCompleted(): void;
}

/**
 * Вью, которое отображает текущую игру
 * @type {React.NamedExoticComponent<Props>}
 */
const GameRace = memo((props: Props) => {
  const {onRaceCompleted} = props;
  const {cartColor, costumeColor} = useSelector(state => ({
    cartColor: state.user.cartColor,
    costumeColor: state.user.costumeColor,
  }));
  const setNavigationShown = useActions(layoutActions.setNavigationShown);
  const [getActiveRace, {loading, error, data, refetch}] =
    useLazyQuery<UserRaceQuery>(
      userRaceQuery,
      {fetchPolicy: 'network-only'},
    );
  const [
    answerQuestion,
  ] = useMutation<AnswerQuestionMutation, AnswerQuestionMutation.Arguments>(
    answerQuestionMutation,
  );
  const [activeRace, setActiveRace] =
    useState<UserRace | null | undefined>(undefined);

  const race = useUserRaceSubscription(true);

  // Выполняет ответ на вопрос
  const onOptionChoose = useCallback(async (optionIndex: number) => {
    if (activeRace) {
      try {
        const {data} = await answerQuestion({
          variables: {
            questionIndex: activeRace.currentQuestion.index,
            optionIndex,
          },
        });

        // В случае правильного ответа получить новый статус заезда
        if (data?.answerQuestion) {
          getActiveRace();
        }
      } catch (e) {
      }
    }
  }, [answerQuestion, activeRace]);

  // Контент для отображения
  let content: ReactNode = null;

  // Как только компонент замаунтился, получаем текущий активный заезд
  useEffect(getActiveRace, [getActiveRace]);

  // Если загрузка текущего активного заезда прошла успешно, помещаем его в
  // состояние
  useEffect(() => {
    if (!loading && !error && data) {
      setActiveRace(data.userRace);
    }
  }, [loading, data, error]);

  // Когда меняется заезд по подписке, меняем в состоянии
  useEffect(() => setActiveRace(race), [race]);

  // Как только activeRace стал null, это означает, что заезд окончен
  useEffect(() => {
    if (activeRace === null) {
      onRaceCompleted();
    }
  }, [activeRace, onRaceCompleted]);

  // When component is mounted, show navigation
  useEffect(() => {
    setNavigationShown(false);
  }, [setNavigationShown]);

  if (loading) {
    content = <ViewLoadingSpinner/>;
  } else if (error) {
    content = (
      <ViewLoadingError>
        Не удалось загрузить информацию об игре
      </ViewLoadingError>
    );
  } else if (activeRace) {
    const {currentQuestion, currentSpeed, currentServerTime} = activeRace;
    const {title, index, options, selectedOptions, sentAt} = currentQuestion;

    content = (
      <div>
        <GameRaceTimer
          questionNumber={index + 1}
          questionsCount={20}
          questionSentAt={sentAt}
          currentTime={currentServerTime}
          speed={currentSpeed}
          onTimeExpired={refetch}
        />
        <GameRaceRoad cartColor={cartColor} costumeColor={costumeColor}/>
        <GameQuestion
          index={index}
          title={title}
          options={options}
          selectedOptions={selectedOptions}
          onOptionChoose={onOptionChoose}
        />
      </div>
    );
  }

  return (
    <View activePanel={'main'}>
      <Panel id={'main'}>
        <PanelHeader>Заезд</PanelHeader>
        {content}
      </Panel>
    </View>
  );
});

export default GameRace;
