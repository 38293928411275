import {ofType, unionize, UnionOf} from 'unionize';
import {unionizeConfig} from '../utils';
import {COLOR} from 'bridge';

export interface UserReducerState {
  cartColor: COLOR;
  costumeColor: COLOR;
  fullName: string;
  isRegistered: boolean;
  profileImageUrl: string | null;
  isAdmin: boolean;
}

export const userActions = unionize({
  setUserRegistered: {},
  setCartColor: ofType<COLOR>(),
  setCostumeColor: ofType<COLOR>(),
}, unionizeConfig);

type UserAction = UnionOf<typeof userActions>;

const initialState: UserReducerState = {
  cartColor: COLOR.BLUE,
  costumeColor: COLOR.GREEN,
  fullName: '',
  isRegistered: false,
  profileImageUrl: '',
  isAdmin: false,
};

/**
 * Редьюсер который отвечает за информацию о пользователе.
 * @param {StorageReducerState} state
 * @param {StorageAction} action
 * @returns {string[]}
 */
function userReducer(
  state: UserReducerState = initialState,
  action: UserAction,
) {
  return userActions.match(action, {
    setUserRegistered: () => ({...state, isRegistered: true}),
    setCartColor: color => ({...state, cartColor: color}),
    setCostumeColor: color => ({...state, costumeColor: color}),
    default: () => state,
  });
}

export default userReducer;
