import {COLOR, FIELD} from 'bridge';

const fieldTitle = {
  [FIELD.ANDROID]: 'Android',
  [FIELD.IOS]: 'iOS',
  [FIELD.BACKEND]: 'Backend',
  [FIELD.FRONTEND]: 'Frontend',
  [FIELD.QA]: 'QA',
};

const colorTitle = {
  [COLOR.BLUE]: 'Синий',
  [COLOR.GREEN]: 'Зеленый',
  [COLOR.PURPLE]: 'Фиолетовый',
  [COLOR.RED]: 'Красный',
  [COLOR.YELLOW]: 'Желтый'
};

/**
 * Возвращает заголовок для направления
 * @param {FIELD} field
 * @returns {string}
 */
export function getFieldTitle(field: FIELD) {
  return fieldTitle[field];
}

/**
 * Возвращает заголовок для цвета
 * @param {COLOR} color
 * @returns {string}
 */
export function getColorTitle(color: COLOR) {
  return colorTitle[color];
}
