import React, {
  memo,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Div from '../../atomic/Div';
import ViewLoadingError from '../../ViewLoadingError';
import RatingUser from './RatingUser';
import ViewLoadingSpinner from '../../ViewLoadingSpinner';

import {useLazyQuery} from '@apollo/react-hooks';
import {
  ratingDayQuery,
  RatingDayQuery,
  ratingDayPageQuery,
  RatingDayPageQuery,
} from 'bridge';
import useSelector from '../../../hooks/useSelector';

type Users = RatingDayQuery.ratingDay.users;

interface Props {
  id: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    paddingBottom: 65,
  },
  placement: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 16,
    lineHeight: '20px',
    letterSpacing: -0.32,
    marginBottom: 10,
  },
}));

const RatingDay = memo((props: Props) => {
  const {id} = props;
  const mc = useStyles(props);
  const userId = useSelector(state => state.config.launchParams.userId);
  const rootRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(0);
  const [users, setUsers] = useState<Users>([]);
  const [
    getRatingDay,
    {loading: ratingDayLoading, data: ratingDay, error: ratingDayError},
  ] = useLazyQuery<RatingDayQuery, RatingDayQuery.Arguments>(
    ratingDayQuery,
    {fetchPolicy: 'network-only'},
  );
  const [
    getRatingDayPage,
    {loading: ratingDayPageLoading, data: ratingDayPage},
  ] = useLazyQuery<RatingDayPageQuery, RatingDayPageQuery.Arguments>(
    ratingDayPageQuery, {fetchPolicy: 'network-only'},
  );
  let content: ReactNode;

  // Как только изменился день, подгружаем новую информацию, очищаем список
  // пользователей
  useEffect(() => {
    getRatingDay({variables: {id}});
    setPage(1);
    setUsers([])
  }, [id, getRatingDay]);

  // Как только достигаем нижней границы экрана, подгружаем новую страницу
  useEffect(() => {
    const listener = () => {
      if (rootRef.current && !ratingDayPageLoading) {
        const root = rootRef.current;
        const {y, height} = root.getBoundingClientRect();

        // Как только до низа остается менее 300 пикселей, подгружаем новую
        // страницу
        if (height - (Math.abs(y) + window.innerHeight) < 500) {
          const nextPage = page + 1;
          getRatingDayPage({
            variables: {
              input: {dayId: id, page: nextPage},
            },
          });
          setPage(nextPage);
        }
      }
    };
    window.addEventListener('scroll', listener);

    return () => window.removeEventListener('scroll', listener);
  }, [id, page, ratingDayPageLoading, getRatingDayPage]);

  // После подгрузки страницы дня, выгружаем пользователей в состояние
  useEffect(() => {
    if (ratingDayPage) {
      setUsers(u => [...u, ...ratingDayPage.ratingDayPage]);
    }
  }, [ratingDayPage]);

  const displayUsers = useMemo(() => {
    if (ratingDay) {
      return [...ratingDay.ratingDay.users, ...users];
    }
    return [];
  }, [users, ratingDay]);

  if (ratingDayLoading) {
    return <ViewLoadingSpinner/>;
  } else if (ratingDayError) {
    content = (
      <ViewLoadingError>
        Не удалось получить информацию об этом дне
      </ViewLoadingError>
    );
  } else if (ratingDay) {
    const {userPosition, usersCount} = ratingDay.ratingDay;
    const positionContent = usersCount === 0
      ? <div className={mc.placement}>В этот день еще никто не ездил</div>
      : (
        userPosition !== null &&
        <div className={mc.placement}>
          Вы на {userPosition} месте из {usersCount}
        </div>
      );

    content = (
      <Div className={mc.root} getRootRef={rootRef}>
        {positionContent}
        {displayUsers.map((u, idx) => (
          <RatingUser
            placement={idx + 1}
            profileImageUrl={u.profileImageUrl}
            name={u.name}
            bestAverageSpeed={u.bestAverageSpeed}
            bestTime={u.bestTime}
            penaltyTime={u.penaltyTime}
            costumeColor={u.costumeColor}
            placementWidth={displayUsers.length.toString().length * 10}
            highlight={u.userId === userId}
            key={idx}
          />
        ))}
      </Div>
    );
  }

  return <>{content}</>;
});

export default RatingDay;
