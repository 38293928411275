import React, {memo, ReactNode, ReactNodeArray} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../theme/types';

import Cell from '@vkontakte/vkui/dist/components/Cell/Cell';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Helmet from './Helmet';

import {formatTime} from '../utils/misc';

import {COLOR} from 'bridge';

interface Props {
  name: string;
  bestAverageSpeed: number | null;
  bestTime: number | null;
  profileImageUrl: string | null;
  costumeColor: COLOR;
  children?: ReactNode | ReactNodeArray;
  highlight?: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    overflow: 'visible',

    '& .Cell__in': {
      padding: 0,
      overflow: 'visible',
    },
    '& .Cell__main': {
      padding: '0 !important',
    },
    '& .Cell__bottom': {
      marginTop: 0,
    },
  },
  helmetContainer: {
    backgroundColor: '#cfecff',
    width: 48,
    height: 48,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  time: {
    color: '#9690EA',
    letterSpacing: -0.154,
    lineHeight: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 6,
  },
  speed: {
    color: '#818C99',
    display: 'flex',
    alignItems: 'center',

    '&:before': {
      display: 'block',
      content: '""',
      height: 12,
      borderRight: `2px ${theme.palette.primary.main} solid`,
      marginRight: 6,
    },
  },
  name: {
    fontSize: 16,
    paddingTop: 6,
    letterSpacing: -0.32,
    lineHeight: '20px',
  },
  avatarContainer: {
    position: ({highlight}) => highlight ? 'relative' : 'static',
    marginRight: 12,

    '&:before': {
      display: ({highlight}) => highlight ? 'block' : 'none',
      content: '""',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      height: 'calc(100% + 4px)',
      width: 'calc(100% + 4px)',
      border: '2px solid #9690EA',
      borderRadius: '50%',
      zIndex: -1,
    },
  },
  avatar: {
    marginRight: '0 !important',
  },
}));

/**
 * Карточка пользователя которая выводит информацию о нем
 * @type {React.NamedExoticComponent<Props>}
 */
const UserCard = memo((props: Props) => {
  const {
    name, bestAverageSpeed, bestTime, profileImageUrl, costumeColor, children,
  } = props;
  const mc = useStyles(props);

  return (
    <Cell
      className={mc.root}
      before={
        <div className={mc.avatarContainer}>
          {profileImageUrl
            ? <Avatar className={mc.avatar} src={profileImageUrl} size={48}/>
            : (
              <div className={mc.helmetContainer}>
                <Helmet color={costumeColor} width={30} height={30}/>
              </div>
            )}
        </div>
      }
      size={'l'}
      bottomContent={
        <div>
          {bestTime !== null &&
          <div className={mc.stats}>
            <div className={mc.time}>{formatTime(bestTime)}</div>
            <div className={mc.speed}>{bestAverageSpeed} км/ч</div>
          </div>}
          {children}
        </div>
      }
    >
      <div className={mc.name}>{name}</div>
    </Cell>
  );
});

export default UserCard;
