import React, {ChangeEvent, memo, useCallback} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';

import VKRadio, {RadioProps} from '@vkontakte/vkui/dist/components/Radio/Radio';
import {tapticSelectionChanged} from '../../utils/taptic';

const useStyles = makeStyles({
  root: {
    padding: '8px 12px',

    '& .Radio__content': {
      color: '#818C99',
      fontSize: 17,
    },

    '& input:checked': {
      '& ~ .Radio__container .Radio__content': {
        color: 'black',
      },

      '&:disabled ~ .Radio__container': {
        '& .Radio__icon': {
          borderColor: '#818C99',

          '&:after': {
            backgroundColor: '#818C99',
          },
        },

        '& .Radio__content': {
          color: '#818C99',
        },
      },
    },
    '& input:disabled ~ .Radio__container': {
      opacity: 1,
    },
  },
});

/**
 * Стилизованный Radio
 * @type {React.NamedExoticComponent<RadioProps>}
 */
const Radio = memo((props: RadioProps) => {
  const {className, onChange, ...rest} = props;
  const mc = useStyles(props);
  const _onChange = useCallback(async (e: ChangeEvent<HTMLInputElement>) => {
    await tapticSelectionChanged();

    if (onChange) {
      onChange(e);
    }
  }, [onChange]);

  return (
    <VKRadio className={c(className, mc.root)} onChange={_onChange} {...rest}/>
  )
});

export default Radio;
