import {COLOR} from 'bridge';

type Colors = Record<COLOR, {
  mask: string;
  helmet: string;
}>;

const colors: Colors = {
  [COLOR.BLUE]: {
    helmet: '#3A88F0',
    mask: '#275183',
  },
  [COLOR.YELLOW]: {
    helmet: '#FFD60A',
    mask: '#867A0B',
  },
  [COLOR.RED]: {
    helmet: '#FF766F',
    mask: '#C63C35',
  },
  [COLOR.PURPLE]: {
    helmet: '#9690EA',
    mask: '#54508F',
  },
  [COLOR.GREEN]: {
    helmet: '#3CA19D',
    mask: '#155B56',
  },
};

/**
 * Возвращает hex-цвета для шлема
 * @param {COLOR} color
 * @returns {string}
 */
export function getColor(color: COLOR) {
  return colors[color];
}

