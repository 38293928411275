import React, {memo, useEffect, useState} from 'react';
import c from 'classnames';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../theme/types';

import VKView, {ViewProps} from '@vkontakte/vkui/dist/components/View/View';

interface Props extends ViewProps {
}

const useStyles = makeStyles<Theme, boolean>({
  root: {
    '& .Panel__separator .Separator__in': {
      background: 'transparent',
    },
    '& .PanelHeader__container': {
      backgroundColor: 'white !important',
    },
    '& .PanelHeader-content': {
      transition: '200ms opacity',
      opacity: showHeader => showHeader ? 1 : 0,
    },
  },
});

/**
 * Расшируние View ВКонтакте которое изменяет видимость PanelHeader
 * @type {React.NamedExoticComponent<Props>}
 */
const View = memo((props: Props) => {
  const {className, ...rest} = props;
  const [showHeader, setShowHeader] = useState(false);
  const mc = useStyles(showHeader);

  useEffect(() => {
    const listener = () => setShowHeader(window.scrollY > 43);

    listener();
    window.addEventListener('scroll', listener);
    return () => window.removeEventListener('scroll', listener);
  }, []);

  return (
    <VKView className={c(mc.root, className)} {...rest}/>
  );
});

export default View;
