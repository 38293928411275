import React, {memo, useMemo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import View from '../View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader
  from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import Avatar from '@vkontakte/vkui/dist/components/Avatar/Avatar';
import Link from '@vkontakte/vkui/dist/components/Link/Link';
import Separator from '../atomic/Separator';
import Div from '../atomic/Div';

import vkLogoUrl from '../../assets/vk-logo.svg';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '& .Panel__in': {
      height: '100%',
    },
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    paddingBottom: 55,
  },
  itemContainer: {
    flex: '1 0 0',
  },
  item: {
    height: 'calc(100% - 1px)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 0,
    paddingBottom: 0,
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: '18px',
    letterSpacing: -0.154,
    textAlign: 'center',
  },
  inner: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 16,
    paddingRight: 16,
  },
  itemImage: {
    marginRight: 20,
  },
}));

const FAQView = memo(() => {
  const mc = useStyles();
  const items = useMemo(() => [{
    title: 'Правила',
    href: '',
  }, {
    title: 'Расписание активностей на стенде',
    href: '',
  }, {
    title: 'Присоединиться к Команде VK',
    href: 'https://m.vk.com/jobs',
  }, {
    title: 'Команда ВКонтакте',
    href: 'https://vk.com/tech',
    imgUrl: vkLogoUrl,
  }, {
    title: 'VK Education',
    href: 'https://vk.com/edu',
    imgUrl: 'https://sun1-29.userapi.com/c846017/v846017044/1edde8' +
      '/psjl9PJBibE.jpg?ava=1',
  }, {
    title: 'Задать вопрос',
    href: 'https://vk.com/vkappsdev',
  }, {
    title: 'Приложение разработано на платформе VK Mini Apps',
  }], []);

  return (
    <View activePanel={'main'}>
      <Panel className={mc.root} id={'main'}>
        <PanelHeader>FAQ</PanelHeader>
        <div className={mc.container}>
          {items.map(({imgUrl, title, href}, idx) => {
            const content = (
              <>
                {imgUrl &&
                <Avatar className={mc.itemImage} src={imgUrl}/>}
                {title}
              </>
            );

            return (
              <div className={mc.itemContainer} key={idx}>
                <Separator/>
                <Div className={mc.item}>
                  {href
                    ? <Link className={mc.inner} href={href}>{content}</Link>
                    : <div className={mc.inner}>{content}</div>}
                </Div>
              </div>
            )
          })}
        </div>
      </Panel>
    </View>
  );
});

export default FAQView;
