import React, {ChangeEvent, memo, useCallback, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Insets from './Insets';
import FixedLayout
  from '@vkontakte/vkui/dist/components/FixedLayout/FixedLayout';
import Button from '@vkontakte/vkui/dist/components/Button/Button';
import FormLayout from '@vkontakte/vkui/dist/components/FormLayout/FormLayout';
import FormLayoutGroup
  from '@vkontakte/vkui/dist/components/FormLayoutGroup/FormLayoutGroup';
import Input from '@vkontakte/vkui/dist/components/Input/Input';
import Radio from '../../atomic/Radio';
import Div from '../../atomic/Div';

import useSelector from '../../../hooks/useSelector';
import {getFieldTitle} from '../../../utils/enums';

import imageUrl from '../../../assets/register-1.base64.svg';

import {FIELD} from 'bridge';
import {UserRegistrationInfo} from './types';

interface Props {
  onCompleted(user: UserRegistrationInfo): void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    height: '100%',
  },
  top: {
    background: `url(${imageUrl}) no-repeat center bottom`,
    backgroundSize: '100% auto',
    height: '40%',
  },
  help: {
    textAlign: 'center',
  },
  form: {
    paddingTop: 13,
    paddingBottom: `calc(62px + var(--safe-area-inset-bottom))`,
  },
  input: {
    [theme.breakpoints.up(320)]: {
      marginLeft: '25px !important',
      marginRight: '25px !important',
    },
  },
  fixed: {
    background: 'linear-gradient(to top, white 0%, #ffffffd9 20%, ' +
      'transparent 100%)',
    paddingTop: 10,
    paddingBottom: 18,
  },
  radio: {
    [theme.breakpoints.up(320)]: {
      paddingLeft: 25,
      paddingRight: 25,
    },
  },
}));

/**
 * Вью регистрации пользователя
 * @type {React.NamedExoticComponent<object>}
 */
const UserView = memo((props: Props) => {
  const {onCompleted} = props;
  const mc = useStyles(props);
  const {fullName} = useSelector(state => ({
    fullName: state.user.fullName,
  }));
  const [name, setName] = useState(fullName);
  const [field, setField] = useState(FIELD.ANDROID);
  const onNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  }, []);
  const nameFilled = name.length > 0;
  const errored = name.length < 3 || name.length > 32;
  const completeEnabled = nameFilled && !errored;

  const onButtonClick = useCallback(() => {
    onCompleted({name, field});
  }, [name, field, onCompleted]);

  return (
    <Insets className={mc.root} bottom={true}>
      <div className={mc.top}/>
      <FormLayout className={mc.form}>
        <Input
          className={mc.input}
          value={name}
          onChange={onNameChange}
          status={nameFilled && errored ? 'error' : 'default'}
          placeholder={'Иван Иванов'}
          bottom={
            nameFilled
              ? errored &&
              <div className={mc.help}>
                Длина имени должна быть в пределах от 3 до 32 символов
              </div>
              : <div className={mc.help}>Укажите свои имя и фамилию</div>
          }
        />
        <Input
          className={mc.input}
          value={getFieldTitle(field)}
          disabled={true}
        />
        <FormLayoutGroup>
          {Object.values(FIELD).map(f => (
            <Radio
              className={mc.radio}
              checked={field === f}
              onChange={() => setField(f)}
              key={f}
            >
              {getFieldTitle(f)}
            </Radio>
          ))}
        </FormLayoutGroup>
      </FormLayout>
      <FixedLayout vertical={'bottom'}>
        <Div className={mc.fixed}>
          <Button
            size={'xl'}
            onClick={onButtonClick}
            disabled={!completeEnabled}
          >
            Далее
          </Button>
        </Div>
      </FixedLayout>
    </Insets>
  );
});

export default UserView;
