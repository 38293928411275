import React, {memo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import withStyles from '@material-ui/styles/withStyles/withStyles';
import {Theme} from '../../../theme/types';

import UserCard from '../../UserCard';

import {formatTime} from '../../../utils/misc';

import {COLOR} from 'bridge';

interface Props {
  placement: number;
  profileImageUrl: string | null;
  name: string;
  bestAverageSpeed: number;
  bestTime: number;
  penaltyTime: number;
  costumeColor: COLOR;
  placementWidth: number;
  highlight?: boolean;
}

const useStyles = makeStyles<Theme, Props>(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',

    '& + &': {
      marginTop: 10,
    },
  },
  penalty: {
    color: ({highlight}) => highlight ? '#FDAD67' : '#909499',
    fontSize: 14,
    lineHeight: '18px',
    letterSpacing: -0.154,
  },
  placement: {
    flex: '0 0 auto',
    width: ({placementWidth}) => placementWidth,
    color: ({highlight}) => highlight ? theme.palette.primary.main : 'black',
    textAlign: 'right',
    fontSize: 15,
    lineHeight: '20px',
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: -0.24,
    marginRight: 12,
    transition: '500ms width',
  },
}));

const StyledUserCard = withStyles({
  root: {
    '& .Cell__in': {
      display: 'flex',
      alignItems: 'center',
    },
    '& .Cell__before': {
      alignSelf: 'initial',
    },
  },
  name: {
    paddingTop: 0,
  },
})(UserCard);

/**
 * Карточка пользователя в турнирной таблице
 * @type {React.NamedExoticComponent<Props>}
 */
const RatingUser = memo((props: Props) => {
  const {placement, penaltyTime, highlight, ...rest} = props;
  const mc = useStyles(props);

  return (
    <div className={mc.root}>
      <div className={mc.placement}>{placement}</div>
      <StyledUserCard highlight={highlight} {...rest}>
        <div className={mc.penalty}>
          штрафное время {formatTime(penaltyTime)}
        </div>
      </StyledUserCard>
    </div>
  );
});

export default RatingUser;
