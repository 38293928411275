import bridge from '@vkontakte/vk-bridge';
import storyBackImageUrl from '../assets/story-back.base64.svg';
import {formatTime} from './misc';

type ShareResultsOptions = {
  time: number;
  appId: number;
} & ({ type: 'common' } | { type: 'semi-final' | 'final'; placement: number });

/**
 * Creates data url with passed fill
 * @param {number} width
 * @param {number} height
 * @param fill
 * @returns {string | null}
 */
function createRectangleDataUrl(
  width: number,
  height: number,
  fill?: string,
): string {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return '';
  }

  canvas.height = height;
  canvas.width = width;

  if (fill) {
    ctx.beginPath();
    ctx.rect(0, 0, width, height);
    ctx.fillStyle = fill;
    ctx.fill();
  }

  return canvas.toDataURL();
}

/**
 * Shares race results
 * @returns {Promise<string extends AnyReceiveMethodName ? ReceiveData<string> : void>}
 * @param options
 */
export async function shareResults(options: ShareResultsOptions) {
  const {appId, time} = options;
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    return;
  }

  const image = await new Promise<HTMLImageElement>((res, rej) => {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.onstalled = rej;
    image.onerror = rej;
    image.onload = () => res(image);
    image.src = storyBackImageUrl;
  });

  const dpiScale = 2;
  const imageWidth = image.width * dpiScale;
  const imageHeight = image.height * dpiScale;
  canvas.height = imageHeight;
  canvas.width = imageWidth;

  // Draw story image
  ctx.drawImage(image, 0, 0, imageWidth, imageHeight);

  // Draw results
  ctx.textAlign = 'center';
  ctx.textBaseline = 'top';
  ctx.fillStyle = '#4986CC';

  // In case, it is common race, draw time only
  if (options.type === 'common') {
    ctx.font = `bold ${34 * dpiScale}px "TT Commons"`;
    ctx.fillText(formatTime(time), canvas.width / 2, 175 * dpiScale);
  }
  // Otherwise, we should display placement, race type and time
  else {
    const {placement, type} = options;
    const lines = [
      `${placement} место`,
      `в ${type === 'semi-final' ? 'полуфинале' : 'финале'}`,
      `${formatTime(time)} время`,
    ];
    const textYStart = 175 * dpiScale;
    const lineHeight = 25 * dpiScale;
    ctx.font = `bold ${dpiScale * 22}px "TT Commons"`;

    lines.forEach((line, idx) => {
      ctx.fillText(line, canvas.width / 2, textYStart + idx * lineHeight);
    });
  }

  const clickableXLeft = 24 * dpiScale;
  const clickableYTop = 338 * dpiScale;
  const clickableXRight = 351 * dpiScale;
  const clickableYBottom = 362 * dpiScale;

  return bridge.send('VKWebAppShowStoryBox', {
    background_type: 'image',
    blob: createRectangleDataUrl(canvas.width, canvas.height, 'white'),
    locked: true,
    stickers: [{
      sticker_type: 'renderable',
      sticker: {
        blob: canvas.toDataURL(),
        transform: {
          relation_width: 1,
        },
        clickable_zones: [{
          action_type: 'link',
          action: {
            link: `https://vk.com/app${appId}`,
            tooltip_text_key: 'tooltip_open_app',
          },
          clickable_area: [
            {x: clickableXLeft, y: clickableYTop},
            {x: clickableXRight, y: clickableYTop},
            {x: clickableXRight, y: clickableYBottom},
            {x: clickableXLeft, y: clickableYBottom},
          ],
        }],
        content_type: 'image',
        original_width: canvas.width,
        original_height: canvas.height,
        can_delete: false,
      },
    }],
  });
}
