import {COLOR} from 'bridge';

type CostumeColors = Record<COLOR, {
  helmet: string;
  mask: string;
  legs: string;
  hands: string;
  body: string;
  bodyShadow: string;
  cart: string;
}>;

const colors: CostumeColors = {
  [COLOR.BLUE]: {
    helmet: '#3A88F0',
    mask: '#275183',
    legs: '#3A88F0',
    hands: '#3A88F0',
    body: '#2D64AE',
    bodyShadow: '#275183',
    cart: '#75B0FF',
  },
  [COLOR.YELLOW]: {
    helmet: '#FFD60A',
    mask: '#867A0B',
    legs: '#CEBC1D',
    hands: '#CEBC1D',
    body: '#AC9B02',
    bodyShadow: '#867A0B',
    cart: '#FFD60A',
  },
  [COLOR.RED]: {
    helmet: '#FF766F',
    mask: '#C63C35',
    legs: '#C63C35',
    hands: '#FF766F',
    body: '#DA473F',
    bodyShadow: '#C63C35',
    cart: '#F04C43',
  },
  [COLOR.PURPLE]: {
    helmet: '#9690EA',
    mask: '#54508F',
    legs: '#9690EA',
    hands: '#9690EA',
    body: '#6D69AB',
    bodyShadow: '#54508F',
    cart: '#BDA6FF',
  },
  [COLOR.GREEN]: {
    helmet: '#3CA19D',
    mask: '#155B56',
    legs: '#3CA19D',
    hands: '#3CA19D',
    body: '#2A7771',
    bodyShadow: '#155B56',
    cart: '#00BDB6',
  },
};

/**
 * Возвращает hex-цвет карта
 * @param {COLOR} color
 * @returns {string}
 */
export function getColors(color: COLOR) {
  return colors[color];
}
