export interface Config {
  apiBaseUrl: string;
  wsUrl: string;
}

let env = 'prod';
const match = window.location.href.match(/^https:\/\/[a-z-]+\.([a-z]+)/);

if (match) {
  env = match[1];
}

// Здесь происходит загрузка конфига на основе переменных внешней среды. Стоит
// не забывать что только переменные, начинающиеся с REACT_APP будут загружены
// react-scripts
const config: Config = {
  // FIXME
  // apiBaseUrl: 'https://ada718983eef.eu.ngrok.io',
  // wsUrl: 'wss://ada718983eef.eu.ngrok.io/gql-ws',
  apiBaseUrl: env === 'staging'
    ? 'https://back.staging.minirace.special.vk-apps.com'
    : `https://back.${env}.minirace.special.vk-apps.com/mini-race/api`,
  wsUrl: `wss://back.${env}.minirace.special.vk-apps.com/gql-ws`,
};

export default config;
