import React, {memo} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../../theme/types';

import Button from '@vkontakte/vkui/dist/components/Button/Button';
import Div from '@vkontakte/vkui/dist/components/Div/Div';

import useInsets from '../../../hooks/useInsets';

import {Insets} from '@vkontakte/vk-bridge';

import roadImageUrl from '../../../assets/onboarding-1-1.base64.svg';
import carImageUrl from '../../../assets/onboarding-1-2.base64.svg';

interface Props {
  onButtonClick(): any;
}

const useStyles = makeStyles<Theme, Insets>(theme => ({
  top: {
    flex: '1 0 0',
    padding: ({top}) => `${top + 50}px 10px 0`,
  },
  title: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: 34,
    fontFamily: theme.typography.fontFamilyTT,
    lineHeight: '34px',
    color: theme.palette.primary.main,
    textAlign: 'center',
    width: '100%',
    maxWidth: 315,

    '&:before': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: -1,
      display: 'block',
      width: '100%',
      height: 'calc(100% + 70px)',
      background: 'radial-gradient(75.22% 62.54% at 53.62% 49.18%, ' +
        '#FFFFFF 30.73%, rgba(255, 255, 255, 0.7) 100%)',
      borderRadius: '50%',
      opacity: .8,
      filter: 'blur(50px)',
    },
  },
  subtitle: {
    fontSize: 16,
    lineHeight: '16px',
    marginTop: 16,
  },
  road: {
    position: 'relative',
    width: '100%',
    height: 'calc(55% - 15px)',
    marginBottom: 15,
    background: `url(${roadImageUrl}) no-repeat center`,
    backgroundSize: 'contain',
  },
  car: {
    width: '100%',
    height: '45%',
    background: `url(${carImageUrl}) no-repeat center`,
    backgroundSize: 'contain',
  },
  bottom: {
    flex: '0 0 auto',
    paddingTop: 20,
  },
}));

/**
 * Первый слайд
 * @type {React.NamedExoticComponent<Props>}
 */
const FirstSlide = memo((props: Props) => {
  const {onButtonClick} = props;
  const insets = useInsets();
  const mc = useStyles(insets);

  return (
    <>
      <div className={mc.top}>
        <div className={mc.road}>
          <div className={mc.title}>
            Добро пожаловать<br/>
            на гонку VK&nbsp;Mini&nbsp;Race
            <div className={mc.subtitle}>
              от создателей VK Mini Apps
            </div>
          </div>
        </div>
        <div className={mc.car}/>
      </div>
      <Div className={mc.bottom}>
        <Button size={'xl'} onClick={onButtonClick}>Далее</Button>
      </Div>
    </>
  );
});

export default FirstSlide;
