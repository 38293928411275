import React, {memo, ReactNode, useCallback, useEffect, useState} from 'react';

import makeStyles from '@material-ui/styles/makeStyles';
import {Theme} from '../../theme/types';

import View from '../View';
import Panel from '@vkontakte/vkui/dist/components/Panel/Panel';
import PanelHeader
  from '@vkontakte/vkui/dist/components/PanelHeader/PanelHeader';
import ViewTitle from '../ViewTitle';
import Separator from '../atomic/Separator';
import Div from '../atomic/Div';
import Car from '../Car';
import ColorSelector from '../ColorSelector';
import UserCard from '../UserCard';
import ViewLoadingSpinner from '../ViewLoadingSpinner';
import ViewLoadingError from '../ViewLoadingError';

import {useMutation, useQuery} from '@apollo/react-hooks';
import {
  userProfileQuery,
  UserProfileQuery,
  updateCartColorMutation,
  UpdateCartColorMutation,
  updateCostumeColorMutation,
  UpdateCostumeColorMutation,
} from 'bridge';

import {getFieldTitle} from '../../utils/enums';
import {tapticNotification} from '../../utils/taptic';
import useActions from '../../hooks/useActions';
import {userActions} from '../../redux/reducers/user';
import useSelector from '../../hooks/useSelector';

const useStyles = makeStyles((theme: Theme) => ({
  userCardContent: {
    color: '#818C99',
  },
  stats: {
    display: 'flex',
    alignItems: 'center',
    fontSize: 14,
  },
  time: {
    color: '#9690EA',
    letterSpacing: -0.154,
    lineHeight: '18px',
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: 6,
  },
  speed: {
    display: 'flex',
    alignItems: 'center',

    '&:before': {
      display: 'block',
      content: '""',
      height: 12,
      borderRight: `2px ${theme.palette.primary.main} solid`,
      marginRight: 6,
    },
  },
  cart: {
    display: 'table',
    margin: '20px auto 10px',
  },
}));

/**
 * Раздел с профилем пользователя
 * @type {React.NamedExoticComponent<any>}
 */
const ProfileView = memo(() => {
  const mc = useStyles();
  let content: ReactNode = null;

  const {initialCartColor, initialCostumeColor} = useSelector(state => ({
    initialCartColor: state.user.cartColor,
    initialCostumeColor: state.user.costumeColor,
  }));
  const {setReduxCartColor, setReduxCostumeColor} = useActions({
    setReduxCartColor: userActions.setCartColor,
    setReduxCostumeColor: userActions.setCostumeColor,
  });
  const [cartColor, setCartColor] = useState(initialCartColor);
  const [costumeColor, setCostumeColor] = useState(initialCostumeColor);

  const {loading, error, data} = useQuery<UserProfileQuery>(userProfileQuery, {
    fetchPolicy: 'network-only',
  });
  const [updateCartColor] =
    useMutation<UpdateCartColorMutation, UpdateCartColorMutation.Arguments>(
      updateCartColorMutation,
    );
  const [updateCostumeColor] = useMutation<UpdateCostumeColorMutation,
    UpdateCostumeColorMutation.Arguments>(
    updateCostumeColorMutation,
  );

  // Сохранение цвета карта
  const onCartColorSave = useCallback(async () => {
    setReduxCartColor(cartColor);
    await tapticNotification('success');
    return updateCartColor({variables: {color: cartColor}});
  }, [updateCartColor, setReduxCartColor, cartColor]);

  // Сохранение цвета костюма
  const onCostumeColorSave = useCallback(async () => {
    setReduxCostumeColor(costumeColor);
    await tapticNotification('success');
    return updateCostumeColor({variables: {color: costumeColor}});
  }, [updateCostumeColor, setReduxCostumeColor, costumeColor]);

  useEffect(() => {
    // Как только данные о профиле пользователя были получены, записываем
    // их в состояние
    if (!loading && data) {
      const {cartColor, costumeColor} = data.userProfile;
      setCartColor(cartColor);
      setCostumeColor(costumeColor);
    }
  }, [loading, data]);

  if (loading) {
    content = <ViewLoadingSpinner/>;
  } else if (error) {
    content = (
      <ViewLoadingError>Не удалось загрузить данные профиля</ViewLoadingError>
    );
  } else if (data) {
    const {
      name, bestAverageSpeed, bestTime, field, profileImageUrl, gamesInDay,
      gamesPlayed,
    } = data.userProfile;

    content = (
      <>
        <Div>
          <UserCard
            name={name}
            bestAverageSpeed={bestAverageSpeed}
            bestTime={bestTime}
            profileImageUrl={profileImageUrl}
            costumeColor={costumeColor}
          >
            <div className={mc.userCardContent}>
              Команда: {getFieldTitle(field)}
              {gamesInDay > 0 &&
              <><br/>Сыграно {gamesPlayed} игр из {gamesInDay} на сегодня</>}
            </div>
          </UserCard>
        </Div>
        <Separator/>
        <Car
          className={mc.cart}
          cartColor={cartColor}
          costumeColor={costumeColor}
        />
        <ColorSelector
          title={'Цвет карта'}
          color={cartColor}
          controlled={true}
          onChange={setCartColor}
          onSave={onCartColorSave}
        />
        <ColorSelector
          title={'Цвет гоночного костюма'}
          color={costumeColor}
          controlled={true}
          onChange={setCostumeColor}
          onSave={onCostumeColorSave}
        />
      </>
    );
  }

  return (
    <View activePanel={'main'}>
      <Panel id={'main'}>
        <PanelHeader>Профиль</PanelHeader>
        <ViewTitle center={true}>Профиль</ViewTitle>
        <Separator/>
        {content}
      </Panel>
    </View>
  );
});

export default ProfileView;
